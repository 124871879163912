@import 'antd/dist/reset.css';

:root {
  // --color-app-bg: #f4f1ec;
  --color-app-bg: #f3f3f3;
}

*,
:after,
:before {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  font-family: sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

ul {
  margin: 0;
  padding: 0;
}

img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

ruby {
  ruby-align: start;
  text-wrap: nowrap;
}

ruby > rt {
  padding: 0;
  color: #888;
  font-size: 11px;
  font-family: monospace;
  text-align: left;
  margin-bottom: 4px;
}

ol {
  padding-left: 20px;
  margin: 0;
}

.shadow-border {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
}

dl {
  margin: 0;
}
dt {
  color: #999;
  font-size: 12px;
}
dd {
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
}

@import 'theme/font-number';

